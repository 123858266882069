.video-feed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: opacity 0.1s ease-in-out;
}

.video-feed.loading {
  opacity: 0.5; /* Change from 0 to 0.5 for a fade effect instead of complete disappearance */
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s ease-in-out;
}

.background-container.fade-out {
  opacity: 0;
}

.background-container.fade-in {
  opacity: 1;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.1s ease-in-out;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.video-container.visible {
  opacity: 1;
}

.mp4-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom, 
    rgba(0,0,0,0.7) 0%, 
    rgba(0,0,0,0.3) 20%, 
    rgba(0,0,0,0) 30%, 
    rgba(0,0,0,0) 70%, 
    rgba(0,0,0,0.3) 80%, 
    rgba(0,0,0,0.7) 100%
  );
  color: white;
}

.content {
  padding: 20px;
}

.content.top {
  align-self: center;
  text-align: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.content.top h2,
.content.top p {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%; /* Adjust this value as needed */
}

.content.bottom {
  align-self: center;
  text-align: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.content h2, .content p {
  text-shadow: 2px 2px 4px rgba(0,0,0,0.8), 
               -2px -2px 4px rgba(0,0,0,0.8), 
               2px -2px 4px rgba(0,0,0,0.8), 
               -2px 2px 4px rgba(0,0,0,0.8);
}

.interaction-buttons {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.interaction-buttons button {
  width: 50px;
  height: 50px;
  border: none;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
}

.interaction-buttons button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.image-carousel {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-carousel img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  z-index: 10;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button.left {
  left: 10px; /* Moved slightly to the right */
}

.carousel-button.right {
  right: 10px; /* Moved slightly to the left */
}

@media (max-width: 768px) {
  .carousel-button {
    display: none;
  }
}

.asset-carousel-container {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px; /* Increased side padding */
  box-sizing: border-box;
}

.asset-carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  padding: 10px 10px; /* Added horizontal padding */
  max-width: calc(100% - 60px); /* Adjust for the increased container padding */
}

.asset-carousel::-webkit-scrollbar { 
  display: none;  /* WebKit */
}

.asset-preview {
  position: relative;
  flex: 0 0 auto;
  width: 160px;
  height: 90px;
  margin-right: 10px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  border: 2px solid transparent;
  background-color: #000; /* Keep this for a black background while loading */
}

.asset-preview:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0,0,0,0.7);
}

.asset-preview.selected {
  border: 2px solid #fff;
  transform: scale(1.05);
  box-shadow: 
    0 0 0 2px #000,
    0 6px 12px rgba(0,0,0,0.7);
}

.asset-preview img,
.asset-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1; /* Change from 0.1 to 1 to always show the asset */
  transition: opacity 0.1s ease-in-out;
}

/* Remove or comment out this rule for now */
/* .asset-preview img.loaded,
.asset-preview video.loaded {
  opacity: 1;
} */

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.1s ease-in-out;
}

.asset-preview.selected {
  border: 2px solid #fff;
  transform: scale(1.05);
  box-shadow: 
    0 0 0 2px #000,
    0 6px 12px rgba(0,0,0,0.7);
}

.asset-preview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.asset-preview video::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

.asset-preview video::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

.asset-preview video::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

/* Optionally, if you want to ensure the assets don't get cut off on smaller screens */
@media (max-width: 768px) {
  .asset-preview {
    width: 120px; /* Slightly smaller on mobile */
    height: 67px; /* Maintain aspect ratio */
  }
}

.video-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.video-icon svg {
  margin-left: 2px; /* Slight adjustment to center the play icon */
}

.background-image,
.mp4-player {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}

.background-image.loaded,
.mp4-player.loaded {
  opacity: 1;
}

.error-message {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}

/* Preload container styles */
.preload-container {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.details-asset {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.details-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
}

.details-overlay h2 {
  font-size: 14px;
  margin-bottom: 5px;
}

.details-overlay p {
  margin-bottom: 5px;
}

.details-overlay .price {
  font-weight: bold;
}

.details-overlay .available-dates {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.details-overlay .available-dates ul {
  list-style-type: none;
  padding-left: 5px;
  margin: 0;
}

.details-overlay .booking-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 3px;
  font-size: 12px;
}




.asset-carousel-container {
  position: relative;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px; /* Increased padding to accommodate buttons */
  box-sizing: border-box;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button.left {
  left: 5px;
}

.carousel-button.right {
  right: 5px;
}

.asset-carousel {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 10px;
  padding: 10px;
  width: 100%;
}

.asset-carousel::-webkit-scrollbar {
  display: none;
}