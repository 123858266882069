.detailed-view {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-gallery img {
  width: calc(33.33% - 10px);
  height: 200px;
  object-fit: cover;
}

.booking-info {
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
}